<template>
  <v-app>
    <v-app-bar app color="#35334AE6" dark>
      <div class="d-flex align-center">
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          :src="require('@/assets/logo_devco.png')"
          transition="scale-transition"
          width="40"
        />
        DEVCO S.A DE C.V
      </div>

      <v-spacer></v-spacer>
      <div class="wrapper" v-if="$vuetify.breakpoint.mdAndUp">
        <a class="text-hover-color white--text" v-scroll-to="'#inicio'" data-after="INICIO">
          <span>INICIO</span>
        </a>
        <a
          class="text-hover-color white--text"
          v-scroll-to="'#nosotros'"
          data-after="NOSOTROS"
          v-if="false"
        >
          <span>NOSOTROS</span>
        </a>
        <a
          class="text-hover-color white--text"
          v-scroll-to="'#servicios'"
          data-after="SERVICIOS"
        >
          <span>SERVICIOS</span>
        </a>
        <a class="text-hover-color white--text" v-scroll-to="'#siie'" data-after="SIIE">
          <span>SIIE</span>
        </a>
        <a
          class="text-hover-color white--text"
          v-scroll-to="'#contacto'"
          data-after="CONTACTO"
        >
          <span>CONTACTO</span>
        </a>
        <a
          class="text-hover-color white--text"
          v-scroll-to="'#politicasPrivacidad'"
          data-after="POLITICAS DE PRIVACIDAD"
          v-if="false"
        >
          <span>POLITICAS DE PRIVACIDAD</span>
        </a>
      </div>

      <v-app-bar-nav-icon
        v-else
        @click.stop="drawer = !drawer"
      ></v-app-bar-nav-icon>
    </v-app-bar>

    <v-navigation-drawer
      app
      v-model="drawer"
      temporary
      right
      dark
      color="#35334AE6"
      :clipped="$vuetify.breakpoint.mdAndUp"
    >
      <v-list dense>
        <v-list-item v-for="item in items" :key="item.title" link>
          <v-list-item-content v-scroll-to="item.href">
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-footer app absolute padless>
      <v-card flat tile width="100%" class="white text-center">
        <v-card-text class="pa-0">
          <v-row no-gutters>
            <v-col cols="6" v-for="(item, i) in items_footer" :key="i">
              <v-btn
                block
                tile
                x-large
                :color="item.color"
                :href="item.href"
                target="_blank"
                :disabled="item.disabled"
              >
                <v-icon size="20px" color="white">
                  {{ item.icon }}
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-text class="black--text">
          2022 — <strong>All right reserved. Developed by Devco</strong>
        </v-card-text>
      </v-card>
    </v-footer>

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  data: () => ({
    drawer: false,
    items: [
      { title: "INICIO", href: "#inicio" },
      { title: "NOSOTROS", href: "#nosotros" },
      { title: "SERVICIOS", href: "#servicios" },
      { title: "SIIE", href: "#siie" },
      { title: "CONTACTO", href: "#contacto" },
    ],
    items_footer: [
      {
        icon: "fab fa-facebook-f",
        color: "#1f1a44",
        href: "https://www.facebook.com/profile.php?id=100066456685999",
        disabled: false,
      },
      {
        icon: "fab fa-instagram",
        color: "#1f1a44",
        href: "https://www.instagram.com/devco.system/",
        disabled: false,
      },
    ],
  }),
};
</script>

<style>
.text-app-bar {
  font-size: 14px;
  margin-right: 12px;
}

.text-hover-color :hover {
  color: aqua;
  transition: 1.0s;
}

.wrapper {
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.wrapper > * {
  margin-right: 2rem;
}
</style>